import { ChangeDetectorRef, Injectable } from "@angular/core";
import { Subject, debounceTime, fromEvent } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GlobalListenersService {
  public handlerMouse;
  public handlerKeyboard;
  public handlerResize;
  public handlerScroll;
  public handlerTouch;
  // public handlerSwipeTouch;
  // public handlerSwipeMove;

  public readonly leftsidebar: any = new Subject<any>();
  readonly leftsidebar$: any = this.leftsidebar.asObservable();

  constructor() {
    this.handlerMouse = fromEvent(document, "click");
    this.handlerKeyboard = fromEvent(document, "keydown");
    this.handlerResize = fromEvent(window, "resize").pipe(debounceTime(500));
    this.handlerScroll = fromEvent(window, "scroll");
    this.handlerTouch = fromEvent(window, "TouchEvent");
    // this.handlerSwipeTouch = fromEvent(document.querySelector("kendo-drawer") as HTMLElement, "touchstart");
    // this.handlerSwipeMove = fromEvent(document.querySelector("kendo-drawer") as HTMLElement, "touchmove");
  }
}
