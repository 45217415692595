import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  ElementRef,
  EventEmitter,
  inject,
  input,
  InputSignal,
  model,
  OnDestroy,
  Output,
  signal,
  ViewChild,
} from "@angular/core";
import { orderBy } from "lodash";
import { GlobalListenersService } from "@services/global-listeners.service";
import { NgClass } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { Subscription } from "rxjs";

@Component({
  selector: "ik-dropdown",
  templateUrl: "./ikdropdown.component.html",
  styleUrls: ["./ikdropdown.component.scss"],
  imports: [NgClass, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class IkdropdownComponent implements OnDestroy {
  elementRef: ElementRef = inject(ElementRef);
  GlobalListenersService: GlobalListenersService = inject(GlobalListenersService);
  cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  readonly openClose: InputSignal<boolean> = input(false);
  readonly data: InputSignal<any> = input([]);
  readonly disabled: InputSignal<boolean> = input(false);
  readonly options: InputSignal<any> = input({});
  readonly isFiltered: InputSignal<boolean> = input(false);
  readonly isArrows: InputSignal<boolean> = input(false);
  readonly position: InputSignal<string> = input("top");
  readonly class: InputSignal<any> = input("");
  public selectedProject: any = model({});
  public show_drop_down = signal(false);

  @ViewChild("dropdown") public dropdown: any;
  @ViewChild("filterInput") public filterInput: any;
  @ViewChild("itemList") itemList: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedProjectOut: EventEmitter<any> = new EventEmitter<any>();

  public _data: any;
  public filterItem: any;

  private handlerMouse$: Subscription;
  private handlerKeyboard$: Subscription;

  constructor() {
    this.handlerMouse$ = this.GlobalListenersService.handlerMouse.subscribe((event: any) => {
      if (!this.show_drop_down()) return;

      if (
        this.dropdown.nativeElement.contains(event.srcElement) ||
        event.srcElement.classList.contains("ikbs-arrow_right") ||
        event.srcElement.classList.contains("filter")
      ) {
        return;
      }

      this.show_drop_down.set(false);
      this.cdRef.detectChanges();
    });
    this.handlerKeyboard$ = this.GlobalListenersService.handlerKeyboard.subscribe((event: any) => {
      if (event.key == "Enter" && this.isFiltered()) {
        for (const item of this._data()) {
          if (item[this.options().title].toLowerCase() === this.filterItem.toLowerCase()) {
            this.filterItem = item[this.options().title];
            this.onChange(item);
            break;
          }
          if (item[this.options().title].toLowerCase().includes(this.filterItem.toLowerCase())) {
            this.filterItem = item[this.options().title];
            break;
          }
        }
      }
      this.cdRef.detectChanges();
    });
    effect(() => {
      if (this._data().length > 7) {
        this.itemList.nativeElement.style["overflow-y"] = "scroll";
      }
      if (this.openClose()) {
        this.show_drop_down.set(!!this.openClose());
      }
      this.cdRef.detectChanges();
    });
    this._data = this._computed();
  }

  ngOnDestroy(): void {
    this.handlerMouse$.unsubscribe();
    this.handlerKeyboard$.unsubscribe();
  }

  selectedClass() {
    if (!this.selectedProject()) return;
    const className = this._data().find((el: any) => (el.text || el) == (this.selectedProject().text || this.selectedProject()));
    return className?.iconClass ? className.iconClass : "";
  }

  _computed() {
    return computed(() => {
      return this.isFiltered() ? orderBy(this.data(), [(item) => item[this.options().value]], ["asc"]) : this.data();
    });
  }

  filter(event: any) {
    this._data = computed(() => {
      return this.data().filter((item: any) => {
        if (this.options().title) {
          return (
            item[this.options().title].toLowerCase().includes(event.toLowerCase()) &&
            !item[this.options().title].toLowerCase().includes(this.selectedProject()[this.options().title].toLowerCase())
          );
        } else {
          return (
            item.text.toLowerCase().includes(event.toLowerCase()) &&
            !item.text.toLowerCase().includes(this.selectedProject().text.toLowerCase())
          );
        }

      });
    });
  }

  filterRefresh() {
    this.filterItem = "";
    this._data = this._computed();
  }

  openList() {
    if (this.disabled()) return;
    if (this.isFiltered()) {
      this.filterRefresh();
      setTimeout(() => {
        this.filterInput.nativeElement.focus();
      }, 1);
    }
    this.show_drop_down.set(!this.show_drop_down());
    this.cdRef.detectChanges();
  }

  onChange(value: any) {
    if (this.disabled()) return;
    if (this.isFiltered()) {
      this.filterRefresh();
    }
    if (this.options().title) {
      for (const item of this._data()) {
        if (item[this.options().value] == value[this.options().value]) {
          this.selectedProject.set(value);
          this.selectedProjectOut.emit(this.selectedProject());
          this.valueChange.emit(item);
          this.show_drop_down.set(false);
          break;
        }
      }
      return;
    }
    for (const item of this._data()) {
      if (item.value == value.value) {
        this.selectedProject.set(value);
        this.selectedProjectOut.emit(this.selectedProject());
        this.valueChange.emit(item);
        this.show_drop_down.set(false);
        break;
      }
    }
    this.cdRef.detectChanges();
  }
}
