<div class="dropdown" #dropdown (click)="openList()" [ngClass]="{ dropdown_disabled: disabled() }" [ngClass]="class()">
  @if (!options().title) {
    <span [ngClass]="selectedClass()">{{
      selectedProject()?.text || selectedProject()
    }}</span>
  }
  @if (options().title) {
    @if (selectedProject()) {
      <span>{{ selectedProject()[options().title] }}</span>
    } @else {
      <span class="empty"> Not Chosen </span>
    }
  }

  @if (isArrows() && selectedProject().type == 'asc') {
    <span class="ikbs-descending"></span>
  }
  @if (isArrows() && selectedProject().type == 'desc') {
    <span class="ikbs-c"></span>
  }
  @if (!show_drop_down()) {
    <span class="ikbs-arrow_right"></span>
  }
  @if (show_drop_down()) {
    <span class="ikbs-arrow_down"></span>
  }
</div>
<ul class="item-list" #itemList [ngClass]="{ show_drop: show_drop_down(), flipped: position() == 'bottom' }">
  @if (isFiltered()) {
    <input #filterInput type="text" (ngModelChange)="filter($event)" [(ngModel)]="filterItem"
      placeholder="Type to Search" class="filter" />
  }

  @if (!options().title) {
    @for (value of _data(); track value) {
      <li [value]="value.value || value" (click)="onChange(value)" class="ikbs_listItem"
        [ngClass]="value?.iconClass">
        <span>{{ value.text || value }}</span>
        @if (
          isArrows() &&
          value?.type == 'asc' &&
          selectedProject()?.text == value.text
          ) {
          <span class="ikbs-descending"></span>
        }
        @if (
          isArrows() &&
          value?.type == 'desc' &&
          selectedProject()?.text == value.text
          ) {
          <span class="ikbs-c"></span>
        }
      </li>
    }
  }

  @if (options().title) {
    @for (value of _data() ; track value) {
      <li [value]="value[options().value]" (click)="onChange(value)" [ngClass]="value?.iconClass"
        class="ikbs_listItem">
        <span>{{ value[options().title] }}</span>
        @if (
          isArrows() &&
          value?.type == 'asc' &&
          selectedProject()?.text == value.text
          ) {
          <span class="ikbs-descending"></span>
        }
        @if (
          isArrows() &&
          value?.type == 'desc' &&
          selectedProject()?.text == value.text
          ) {
          <span class="ikbs-c"></span>
        }
      </li>
    }
  }
</ul>
<!-- <select [ngModel]="selectedProject().value" (ngModelChange)="onChange($event)" >
<option  *ngFor="let value of data" [value]='value.value' >{{value.text}}</option>
</select> -->